<script setup>
import IndividualOfferBadge from "~/components/Vector/IndividualOfferBadge.vue";

const props = defineProps({
  header: String,
  selectedBodyIndex: Number,
  cars: Array
})

const route = useRoute();
const calculator = useCalculator();

const emits = defineEmits(['change'])
const bodyTypes = computed(() => props.cars.map(car => car.bodyType.toLowerCase()));
const selected = ref(bodyTypes.value.find(Boolean).toLowerCase())
const hash = computed(() => "#" + selected.value);
const available = computed(() => props.cars.find(car => car.bodyType.toLowerCase() === selected.value).count)

const brand = computed(() => props.cars?.[0].brandName);
const model = computed(() => props.cars?.[0].modelName);

const onChange = (value) => {
  selected.value = value;
  emits('change', value);
}

onMounted(() => {
  const hash = route.hash.substring(1).toLowerCase();
  const index = Math.max(0, bodyTypes.value.indexOf(hash));
  selected.value = bodyTypes.value[index].toLowerCase();
  calculator.getInstallment({data: props.cars})

  if(index > 0) {
    onChange(selected.value);
  }
})
</script>

<template>
  <div class="py-6">
    <h1 class="text-2xl font-light">{{ header }}</h1>
    <div class="relative z-20 font-extralight flex divide-x divide-black leading-none text-sm mt-2">
      <div v-for="body in bodyTypes"
           class="px-2 cursor-pointer first:pl-0"
           :class="[{'text-azure font-normal': selected === body.toLowerCase()}]"
           @click="onChange(body.toLowerCase())"
      >{{ body.toLowerCase() }}
      </div>
    </div>
    <div class="relative z-10 flex items-center justify-end -mt-14 -mb-14 h-[368px]">
      <div class="-mx-8 absolute left-0 -bottom-2 right-0 h-[140px] bg-gradient-to-t from-gray-100 via-gray-200 to-gray-400 z-0"></div>
      <template v-for="car in props.cars">
        <div
          class="absolute mt-14 lg:mt-0 h-[368px] flex flex-col justify-center lg:justify-start items-center pr-0 xl:pr-32 transition-opacity overflow-hidden"
          :class="[
               {'opacity-100': selected === car?.bodyType.toLowerCase()},
               {'opacity-0': selected !== car?.bodyType.toLowerCase()}
               ]">
          <NuxtImg v-if="car?.image" :src="car?.image" fit="cover" class="w-[580px] lg:w-[700px]" :alt="`Leasing `+ car?.brandName + ' ' + car?.modelName + ' ' + car?.bodyType" />
        </div>
      </template>

    </div>
    <div class="relative z-20">
      <ButtonRounded v-if="!!available" :href="{name: 'cars', params: {brand: brand.toLowerDashed(), model: model.toLowerDashed()}, hash: hash}" fit>Zobacz dostępne samochody</ButtonRounded>
      <div v-else class="relative h-10"><IndividualOfferBadge class="absolute -top-4"/></div>
    </div>

  </div>
</template>
